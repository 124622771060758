import { paths } from "src/routes/paths"

import { CONFIG } from "src/config-global"

import { SvgColor } from "src/components/svg-color"

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`${CONFIG.site.basePath}/assets/icons/navbar/${name}.svg`} />

const ICONS = {
    job: icon("ic-job"),
    blog: icon("ic-blog"),
    chat: icon("ic-chat"),
    mail: icon("ic-mail"),
    user: icon("ic-user"),
    file: icon("ic-file"),
    lock: icon("ic-lock"),
    tour: icon("ic-tour"),
    order: icon("ic-order"),
    label: icon("ic-label"),
    blank: icon("ic-blank"),
    kanban: icon("ic-kanban"),
    folder: icon("ic-folder"),
    course: icon("ic-course"),
    banking: icon("ic-banking"),
    booking: icon("ic-booking"),
    invoice: icon("ic-invoice"),
    product: icon("ic-product"),
    calendar: icon("ic-calendar"),
    disabled: icon("ic-disabled"),
    external: icon("ic-external"),
    menuItem: icon("ic-menu-item"),
    ecommerce: icon("ic-ecommerce"),
    analytics: icon("ic-analytics"),
    dashboard: icon("ic-dashboard"),
    parameter: icon("ic-parameter"),
    checklist: icon("ic-checklist"),
    launch: icon("ic-launch"),
    interview: icon("ic-interview"),
    selection: icon("ic-selection"),
    briefcase: icon("ic-briefcase"),
    userManagement: icon("ic-user-management"),
}

// ----------------------------------------------------------------------

export const navData = [
    /**
     * Overview
     */
    {
        subheader: "Overview",
        items: [
            { title: "Get Started", path: paths.dashboard.onboarding, icon: ICONS.launch },
            { title: "Candidates", path: paths.dashboard.root, icon: ICONS.user },
            { title: "Interviews", path: paths.dashboard.interviews, icon: ICONS.interview },
            { title: "Selections", path: paths.dashboard.selections, icon: ICONS.selection },
            { title: "Job Roles", path: paths.dashboard.mandates, icon: ICONS.briefcase },
            // { title: "Clients", path: paths.dashboard.clients, icon: ICONS.kanban },
            { title: "History", path: paths.dashboard.bulkUpload, icon: ICONS.briefcase },
            {
                title: "User Management",
                path: paths.dashboard.userManagement,
                icon: ICONS.userManagement,
                permissions: ["viewUserManagementPage"],
            },
        ],
    },
    /**
     * Management
     */
    /*{
        subheader: "Management",
        items: [
            {
                title: "Access management",
                path: paths.dashboard.group.root,
                icon: ICONS.parameter,
            },
        ],
    },*/
]

export const navDataAiProcessing = [
    /**
     * Overview
     */
    {
        subheader: "Overview",
        items: [
            { title: "Get Started", path: paths.dashboard.onboarding, icon: ICONS.launch },
            { title: "Candidates", path: paths.dashboard.root, icon: ICONS.user },
            { title: "Job Roles", path: paths.dashboard.mandates, icon: ICONS.briefcase },
            { title: "Interviews", path: paths.dashboard.interviews, icon: ICONS.interview },
            { title: "Selections", path: paths.dashboard.selections, icon: ICONS.selection },
            //{ title: "Clients", path: paths.dashboard.clients, icon: ICONS.kanban },
            { title: "History", path: paths.dashboard.bulkUpload, icon: ICONS.briefcase },
            {
                title: "User Management",
                path: paths.dashboard.userManagement,
                icon: ICONS.userManagement,
                permissions: ["viewUserManagementPage"],
            },
        ],
    },
]
