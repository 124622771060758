import type { ReactNode } from "react"
import type { BulkUpload } from "@/src/types/bulkUploadsModel"

import { toast } from "sonner"
import { useBulkUpload } from "@/src/actions/bulkUploads"
import React, { useMemo, useState, useContext, useCallback, createContext } from "react"

const MagicSourceStatusContext = createContext<{
    pollStatus: (bulkJobId: string) => void
    bulkUploadResult: BulkUpload | undefined
}>()

export const useMagicSourceStatus = () => {
    return useContext(MagicSourceStatusContext)
}

export const MagicSourceStatusProvider = ({ children }: { children: ReactNode }) => {
    const [bulkResult, setBulkResult] = useState<BulkUpload | undefined>()

    const { startBulkUpload } = useBulkUpload((data) => {
        setBulkResult(data)
        if (data.status === "COMPLETED") {
            toast.success(`${data.success} candidate${data.success === 1 ? "" : "s"} sourced successfully`)
        } else if (data.status === "FAILED") {
            let title: string
            let errorMessage: string

            if (data.comments) {
                const message = data.comments.substring(data.comments.indexOf("{"), data.comments.lastIndexOf("}") + 1)
                try {
                    errorMessage = JSON.parse(message).statusMessage
                } catch (error) {
                    errorMessage = "Magic sourcing failed"
                }
            } else {
                errorMessage = "Magic sourcing failed"
            }

            if (data.success) title = `${data.success} candidate${data.success === 1 ? "" : "s"} sourced successfully`
            else title = "Magic sourcing failed"

            toast.error(title, { description: errorMessage })
        }
        return Promise.resolve()
    }, true)

    const pollStatus = useCallback(
        (bulkJobId: string | undefined) => {
            if (!bulkJobId) return
            startBulkUpload({ data: { data: { id: bulkJobId } } })
        },
        [startBulkUpload]
    )

    const value = useMemo(() => ({ pollStatus, bulkUploadResult: bulkResult }), [pollStatus, bulkResult])

    return <MagicSourceStatusContext.Provider value={value}>{children}</MagicSourceStatusContext.Provider>
}
