import type { GridSortModel, GridFilterItem } from "@mui/x-data-grid-pro"

import useSWR from "swr"
import axios from "axios"
import { toast } from "sonner"
import { useRef, useMemo, useState, useEffect } from "react"

import axiosInstance, { fetcher, endpoints } from "src/utils/axios"

import { swrOptions, getTableFilterString } from "./leads"

import type { ApiResponse } from "../types/apiResponse"
import type { BulkUpload, BulkUploadTableFilters } from "../types/bulkUploadsModel"

// ----------------------------------------------------------------------

type BulkUploadData = {
    data: {
        content: BulkUpload[]
        totalElements: number | undefined
        bulkJobCountByStatus: {
            [key: string]: number
        }
    }
}

export function useGetBulkUploads(
    page: number,
    size: number,
    sortModel: GridSortModel,
    search: string,
    filterOptions: GridFilterItem[],
    extraFilters: BulkUploadTableFilters,
    isMagicLeads?: boolean
) {
    const url = endpoints.bulkUpload.list

    const filterString = getTableFilterString(sortModel, search, filterOptions, extraFilters)

    const { data, isLoading, error, isValidating, mutate } = useSWR<BulkUploadData>(
        `${url}?page=${page}&size=${size}${filterString}&bulk_job_type=${isMagicLeads ? "GET_MAGIC_LEADS" : "BULK_JOB"}`,
        fetcher,
        swrOptions
    )

    const memoizedValue = useMemo(
        () => ({
            uploads: (data?.data?.content || []).map(mapUpload),
            uploadsCount: data?.data?.totalElements,
            uploadsLoading: isLoading,
            tabData: data?.data?.bulkJobCountByStatus,
            uploadsError: error,
            uploadsValidating: isValidating,
            uploadsEmpty: !isLoading && !data?.data?.content?.length,
            uploadsMutate: mutate,
        }),
        [data, error, isLoading, isValidating, mutate]
    )

    return memoizedValue
}

const mapUpload = (data: any): BulkUpload => ({
    ...data,
    createdBy: { label: data.createdBy?.name, value: data.createdBy?.id },
    mandate: { label: data.mandate?.name, value: data.mandate?.id, agentId: data.mandate?.agentId },
    recruiters: data.recruiters?.map((recruiter: any) => ({ label: recruiter.name, value: recruiter.id })) ?? [],
})

export async function uploadCsv(payload: any, fileName: string, fileType: string) {
    const url = `${endpoints.bulkUpload.csvUpload}?fileName=${fileName}&fileType=${fileType}`
    try {
        const resp = await axiosInstance.get(url, payload)
        return {
            status: "success",
            data: resp?.data,
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: "error",
            errorMessage: e.status,
        }
    }
}

export function uploadCsvFiles(url: string, payload: any) {
    const headers = {
        "Content-Type": "application/octet-stream",
    }
    return axios
        .put(url, payload, { headers })
        .then((resp): ApiResponse<void> => {
            return {
                status: "success",
                data: resp?.data,
            }
        })
        .catch((e): ApiResponse<void> => {
            console.log(e)
            return {
                status: "error",
                errorMessage: e.status,
            }
        })
}

export async function createUploadLeads(payload: any) {
    const url = `${endpoints.bulkUpload.uploadLeads}`
    try {
        const resp = await axiosInstance.post(url, payload)
        return {
            status: "success",
            data: resp?.data,
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: "error",
            errorMessage: e.status ?? e.message,
        }
    }
}

export async function createMagicSourcing(payload: { mandateId: string; recruiters: string[] }, sourceJobId: string) {
    const url = `${endpoints.bulkUpload.uploadLeads}`
    try {
        const resp = await axiosInstance.post(url, {
            type: "GET_MAGIC_LEADS",
            metadata: payload,
            source: "GET_MAGIC_LEADS",
            // sourceJobId,
        })
        return {
            status: "success",
            data: resp?.data,
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: "error",
            errorMessage: e.message ?? e.status,
        }
    }
}

/* export const updateClientForm = (payload: FormSchemaClientType) => {
    return axiosInstance.post(endpoints.clients.clientForm, payload)
}

export const editClientForm = (payload: FormSchemaClientType, id: string) => {
    return axiosInstance.put(`${endpoints.clients.clientForm}/${id}`, payload)
} */

const pollBulkUpload = async (
    jobId: string
): Promise<ApiResponse<{ status: string; downloadUrl: string; comments: string; success: number }>> => {
    const url = `${endpoints.leads.exportLeads}/${jobId}`
    try {
        const resp = await axiosInstance.get(url)
        if (resp?.data?.data?.status) {
            return {
                status: "success",
                data: {
                    ...resp.data.data,
                    downloadUrl: resp.data.data.reportUrl,
                },
            }
        }
        return {
            status: "error",
            errorMessage: "Uploading candidates failed",
            data: resp.data.data,
        }
    } catch (e: any) {
        console.log(e)
        return {
            status: "error",
            errorMessage: "Uploading candidates failed",
        }
    }
}

interface BulkUploadResp {
    percentage: number
}

export const useBulkUpload = (
    onComplete: (data: BulkUpload) => Promise<void>,
    suppressErrorToast?: boolean
): {
    bulkUploadResult: BulkUploadResp | undefined
    startBulkUpload: (exportStartRes: any) => void
} => {
    const jobId = useRef<string | null>()
    const pollTimer = useRef<number | null>()
    const [exportResult, setExportResult] = useState<BulkUploadResp | undefined>()

    const clearPollTimer = () => {
        jobId.current = null
        setExportResult(undefined)
        if (pollTimer.current) {
            clearTimeout(pollTimer.current)
            pollTimer.current = null
        }
    }

    useEffect(() => {
        clearPollTimer()
        return () => {
            clearPollTimer()
        }
    }, [])

    const pollExportStatus = async () => {
        if (!jobId.current) {
            clearPollTimer()
            return
        }

        const exportStatus = await pollBulkUpload(jobId.current)

        // Not handling failures of a single poll

        if (exportStatus.data?.status === "REQUEST_ACCEPTED") setExportResult({ percentage: 10 })
        else if (exportStatus.data?.status === "PROCESSING") setExportResult({ percentage: 50 })
        else if (exportStatus.data?.status === "COMPLETED") {
            setExportResult({ percentage: 99 })
            await onComplete(exportStatus.data as any)
            setExportResult(undefined)
            clearPollTimer()
            return
        } else if (exportStatus.data?.status === "FAILED") {
            await onComplete(exportStatus.data as any)
            if (!suppressErrorToast) toast.error("Failed to export candidates")
            clearPollTimer()
            return
        }
        setTimeout(pollExportStatus, 2000)
    }

    const startExport = async (exportStartRes: any) => {
        setExportResult({ percentage: 0 })
        jobId.current = exportStartRes.data.data.id
        pollExportStatus()
    }

    return { bulkUploadResult: exportResult, startBulkUpload: startExport }
}
