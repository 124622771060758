import type { AxiosRequestConfig } from "axios"

import axios from "axios"
import { toast } from "sonner"
import { paths } from "@/src/routes/paths"

import { CONFIG } from "src/config-global"

// ----------------------------------------------------------------------
const REQUEST_RETRY_HEADER = "Pph-Token-Retry"

const axiosInstance = axios.create({ baseURL: CONFIG.site.serverUrl })

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const statusCode = error.response?.status
        const originalRequestConfig = error.config

        if (statusCode === 401 && !originalRequestConfig.headers[REQUEST_RETRY_HEADER]) {
            const url = error.config.url
            if (url !== endpoints.auth.signIn && url !== endpoints.auth.signUp) {
                return getTokenFromRefresh().then((newToken) => {
                    if (newToken) {
                        originalRequestConfig.headers.Authorization = `Bearer ${newToken}`
                        originalRequestConfig.headers[REQUEST_RETRY_HEADER] = "true"
                        return axiosInstance.request(originalRequestConfig)
                    }

                    window.location.href = paths.auth.jwt.signIn
                    return Promise.reject((error.response && error.response.data) || "Something went wrong!")
                })
            }
        }
        if (error.code !== "ERR_CANCELED") {
            toast.error(
                error.response?.data
                    ? error.response.data.errorMessage ??
                          error.response.data.message ??
                          error.response.data.statusMessage ??
                          error.response.data
                    : "Something went wrong"
            )
        }
        return Promise.reject((error.response && error.response.data) || "Something went wrong!")
    }
)

export default axiosInstance

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
    try {
        const [url, config] = Array.isArray(args) ? args : [args]

        const res = await axiosInstance.get(url, { ...config })

        return res.data
    } catch (error) {
        console.error("Failed to fetch:", error)
        throw error
    }
}

export function removeUserToken() {
    localStorage.removeItem("jwt_access_token")
    localStorage.removeItem("jwt_refresh_token")
    delete axios.defaults.headers.common.Authorization
    delete axiosInstance.defaults.headers.common.Authorization
}

export async function getTokenFromRefresh(): Promise<string | undefined> {
    const refreshToken = localStorage.getItem("jwt_refresh_token")
    if (!refreshToken) {
        removeUserToken()
        return undefined
    }

    try {
        const resp = await axiosInstance.post(
            endpoints.auth.getNewToken,
            { refreshToken },
            {
                transformRequest: (data, headers) => {
                    headers["Content-Type"] = "application/json"
                    delete headers.Authorization
                    return JSON.stringify(data)
                },
            }
        )
        const accessToken = resp?.data?.data?.token
        const newRefreshToken = resp?.data?.data?.refreshToken

        if (accessToken) {
            localStorage.setItem("jwt_access_token", accessToken)
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
            if (newRefreshToken) localStorage.setItem("jwt_refresh_token", newRefreshToken)
            return accessToken
        }
        removeUserToken()
        return undefined
    } catch (error) {
        removeUserToken()
        return undefined
    }
}

// ----------------------------------------------------------------------

export const endpoints = {
    chat: "/api/chat",
    config: {
        appConfig: "/pph/api/config/status-config",
    },
    auth: {
        me: "pph/api/users/profile",
        googleLogin: "/pph/api/auth/google/login",
        signIn: "/pph/api/auth/login",
        signUp: "/pph/api/auth/signup",
        users: "/pph/api/users/all",
        getNewToken: "/pph/api/auth/refresh-token",
    },
    selections: {
        list: "/pph/api/selections/all",
        details: "http://localhost:3000/selection",
        statusUpdate: "/pph/api/selections",
        selectionForm: "/pph/api/selections",
    },
    interviews: {
        list: "/pph/api/interviews/all",
        details: "/pph/api/interviews/",
        post: "/pph/api/interviews",
        postBulk: "/pph/api/interviews/bulk",
        update: "/pph/api/interviews",
        roundUpdate: "/pph/api/interviews",
    },
    mandates: {
        list: "/pph/api/mandates/all",
        details: "/pph/api/mandates/",
        jobDetails: "/pph/api/mandates/apna-job-details",
        bulkSource: "/pph/api/bulk-jobs",
        detailsPublic: "/pph/api/public/mandate/",
        update: "/pph/api/mandates",
        draft: "/pph/api/mandates/draft",
        dropdown: "/pph/api/mandates/dropdown",
        activate: "/pph/api/mandates/:id/activate",
        templates: "/pph/api/config/screening-questions-template",
        aiRecruiterType: "/pph/api/mandates/agents",
        aiRecruiterTypeForMandate: (mandateId: string) => `/pph/api/mandates/${mandateId}/recommended-agents`,
        enableAi: (mandateId: string) => `/pph/api/mandates/${mandateId}/agent`,
    },
    clients: {
        list: "/pph/api/clients/all",
        details: "/pph/api/clients/",
        clientForm: "/pph/api/clients",
    },
    leads: {
        list: "/pph/api/leads/all",
        statusUpdate: "/pph/api/leads",
        update: "/pph/api/leads",
        details: "/pph/api/leads/",
        leadsForm: "/pph/api/leads",
        creatLeads: "/pph/api/public/leads/create",
        callback: "/pph/api/leads/schedule-callback",
        exportLeads: "/pph/api/bulk-jobs",
        reEvaluate: "/pph/api/lead-scoring/re-evaluate-lead",
    },
    file: {
        upload: "/pph/api/leads/parse/resume",
        genericUpload: "/pph/api/leads/upload/file?fileType=",
        uploadResume: "/pph/api/public/parse/resume",
    },
    users: {
        getRecruiters: "/pph/api/leads/all",
        list: "/pph/api/users/company-users",
        addUser: "/pph/api/users/create",
        editUser: "/pph/api/users",
        updateUser: "/pph/api/users/update",
        managerList: "/pph/api/users/company/managers",
        rolePermission: "/pph/api/admin/permissions",
        roleAssign: "/pph/api/admin/assign-permission",
        deletePermission: "/pph/api/admin/remove-permission",
        allRoles: "/pph/api/admin/roles",
    },
    bulkUpload: {
        list: "/pph/api/bulk-jobs/all",
        uploadLeads: "/pph/api/bulk-jobs",
        csvUpload: "/pph/api/config/signed-url/file-upload",
        resumeUploadPublic: "/pph/api/public/signed-url/file-upload",
        fileDownload: "/pph/api/config/signed-url/file-download-from-url",
    },
    jobTitle: {
        list: "/pph/api/suggester?size=20",
    },
    ai: {
        generateCandidateEntityDetails: "/pph/api/mandates/extract-mandate-attributes",
        extractMandateDetailsFromPrompt: "/pph/api/mandates/extract-mandate-details",
    },
    onboarding: {
        fetchSteps: "/pph/api/users/onboarding-steps-completion",
        onboardingCompleted: "/pph/api/users/onboarding-completed",
    },
}
