import type { GridSortModel, GridFilterItem } from "@mui/x-data-grid-pro"
import type { FormSchemaLeadsType } from "@/src/sections/blankForm/blankFormLeads"

import useSWR from "swr"
import dayjs from "dayjs"
import { useMemo } from "react"

import axiosInstance, { fetcher, endpoints } from "src/utils/axios"

import type { LabelValuePair } from "../types/labelValuePair"
import type { LeadsModel, LeadTableFilters } from "../types/leadsModel"

// ----------------------------------------------------------------------

export const swrOptions = {
    revalidateOnFocus: false,
}

// ----------------------------------------------------------------------

type LeadsData = {
    data: { content: LeadsModel[]; totalElements: number | undefined; leadCountPerStatus: { [key: string]: number } }
}

export const tableFiltersToJson = (
    search: string,
    filterOptions: GridFilterItem[],
    extraFilters: { [key: string]: dayjs.Dayjs | LabelValuePair<string>[] | string[] | undefined }
) => {
    const allFilters: any = {}

    if (search) allFilters.search = search

    filterOptions.forEach((filter) => {
        if (filter.value) allFilters[filter.field] = Array.isArray(filter.value) ? filter.value.join(",") : filter.value
    })

    Object.entries(extraFilters).forEach((item) => {
        let value
        if (Array.isArray(item[1])) {
            value = item[1].map((e) => (e as any).value ?? e).join(",")
        } else {
            value = item[1]
        }
        if (dayjs.isDayjs(value)) value = dayjs(value).format("YYYY-MM-DD")

        if (value) allFilters[item[0]] = value
    })

    return allFilters
}

export const getTableFilterString = (
    sortModel: GridSortModel,
    search: string,
    filterOptions: GridFilterItem[],
    extraFilters: { [key: string]: dayjs.Dayjs | LabelValuePair<string>[] | string[] | undefined }
) => {
    const sortQuery = sortModel
        .map((e) => {
            const field = e.field === "matchScoreResult" ? "matchScore" : e.field
            return `${field},${e.sort}`
        })
        .join("&")
    let filters = filterOptions
        .filter((e) => e.value)
        .map((e) => `${e.field}=${e.value}`)
        .join("&")

    const extraFilterValues: string[] = []

    Object.entries(extraFilters).forEach((item) => {
        let value
        if (Array.isArray(item[1])) {
            value = item[1].map((e) => (e as any).value ?? e).join(",")
        } else {
            value = item[1]
        }
        if (dayjs.isDayjs(value)) value = dayjs(value).format("YYYY-MM-DD")

        if (value) extraFilterValues.push(`${item[0]}=${value}`)
    })
    if (extraFilterValues.length) {
        filters = filters ? `${filters}&${extraFilterValues.join("&")}` : extraFilterValues.join("&")
    }

    return `${sortQuery ? `&sort=${sortQuery}` : ""}${search ? `&search=${search}` : ""}${filters ? `&${filters}` : ""}`
}

export function useGetLeads(
    page: number,
    size: number,
    sortModel: GridSortModel,
    search: string,
    filterOptions: GridFilterItem[],
    extraFilters: LeadTableFilters
) {
    const url = endpoints.leads.list
    const filterString = getTableFilterString(sortModel, search, filterOptions, extraFilters)

    const { data, isLoading, error, isValidating, mutate } = useSWR<LeadsData>(
        `${url}?page=${page}&size=${size}${filterString}`,
        fetcher,
        swrOptions
    )

    const memoizedValue = useMemo(() => {
        const l = (data?.data?.content || []).map(leadsMapper)
        //l = [...l, ...l, ...l, ...l].map((e, i) => ({ ...e, id: `${e?.id}${i}` }))
        return {
            leads: l,
            leadsCount: data?.data?.totalElements,
            leadsLoading: isLoading,
            tabData: data?.data?.leadCountPerStatus,
            leadsError: error,
            leadsValidating: isValidating,
            leadsEmpty: !isLoading && !data?.data?.content.length,
            leadsMutate: mutate,
        }
    }, [data, error, isLoading, isValidating, mutate])

    return memoizedValue
}

// ----------------------------------------------------------------------

type LeadData = {
    data: LeadsModel
}

export function useGetLead({ leadId, page = 0, size }: { leadId: string | undefined; page: number; size: number }) {
    const url = leadId
        ? [`${endpoints.leads.details}${leadId}`, { params: { page, size, sort: "createdDate%2Cdesc" } }]
        : ""

    const { data, isLoading, error, isValidating } = useSWR<LeadData>(url, fetcher, swrOptions)

    const memoizedValue = useMemo(
        () => ({
            lead: leadsMapper(data?.data),
            leadLoading: isLoading,
            leadError: error,
            leadValidating: isValidating,
        }),
        [data, error, isLoading, isValidating]
    )

    return memoizedValue
}

const leadsMapper = (data: any | undefined): LeadsModel | undefined => {
    if (!data) return undefined

    return {
        ...data,
        assignedTo: { label: data.assignedTo?.jobTitle, value: data.assignedTo?.id },
        createdBy: { label: data.createdBy?.name, value: data.createdBy?.id },
        mandate: { label: data.mandate?.displayName, value: data.mandate?.id },
        client: { label: data.client?.name, value: data.client?.id },
        mandateJobTitle: data.mandate?.jobTitle,
        mandateCity: data.mandate?.city,
    }
}

export const updateLeadDetails = (
    data: Partial<LeadsModel> & {
        id: string
    }
) => {
    return axiosInstance.patch(endpoints.leads.statusUpdate, {
        leadId: data.id,
        status: data.status,
        subStatus: data.subStatus,
        comment: data.comments,
        resumeLink: data.resumeLink,
        callbackDate: data.callbackDate ? dayjs(data.callbackDate).format() : undefined,
        assignedTo: data.assignedTo?.value,
        reason: data.reason,
    })
}

export const updateLeadsForm = (payload: FormSchemaLeadsType) => {
    return axiosInstance.post(endpoints.leads.leadsForm, payload)
}

export const updateLeadsFormPublic = (payload: FormSchemaLeadsType) => {
    return axiosInstance.post(endpoints.leads.creatLeads, payload)
}

export const editLeadsForm = (payload: FormSchemaLeadsType, id: string) => {
    return axiosInstance.put(`${endpoints.leads.update}/${id}`, payload)
}
